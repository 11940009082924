<template>
  <Drawer
    :visible="visible"
    title="Все фильтры"
    :position="position"
    :width="(DocumentBreakpointsUtils.getIsRenderedMobile() && '90%') || undefined"
    :z-index="33"
    :disable-submit="isFiltersInvaild"
    :submit-loader="submitLoader"
    @cancel="$emit('cancel')"
    @submit="onSubmit"
    @close="$emit('close')"
  >
    <div class="filters-drawer__content">
      <div
        v-if="selectedFilters?.length"
        class="filters-drawer__content-actions"
      >
        <div
          v-for="(selectedFilter) in selectedFilters"
          :key="selectedFilter.filter.id"
          class="filters-drawer__content-actions__item"
        >
          <DropdownFilter
            v-model="selectedFilter.filterState"
            :filter="selectedFilter.filter"
            :disabled="submitLoader?.value"
            class="filter"
            container-class="drawer-filter"
            @reset="onResetFitler(selectedFilter)"
          />
        </div>

        <div class="filters-drawer__content-actions__item">
          <button
            class="btn btn-secondary filters-drawer__clear"
            :disabled="submitLoader?.value"
            @click="onClearAllFilters"
          >
            Очистить все
            <SvgIcon src="navigation/close-20px" />
          </button>
        </div>
      </div>

      <BaseFilter
        v-for="(filter, key) in internalFilters"
        :key="key"
        v-model="filter.filterState"
        :filter="filter.filter"
        :disabled="submitLoader?.value"
        class="filters-drawer__item"
        collapsible
        title-class="filters-drawer__item-title"
        :base-filter-props="getFilterBaseProps?.(filter.filter)"
      />
    </div>
  </Drawer>
</template>

<script lang="ts" setup>
import { IBaseFilterProps, IFilter, IFilterItem } from '../../models/filters.model';
import BaseFilter from '../BaseFilter.vue';
import Drawer from '../Drawer.vue';
import { cloneDeep } from 'lodash-es';
import { FilterUtils } from '../../utils/filterHelper.util';
import DropdownFilter from '../DropdownFilter.vue';
import { DocumentBreakpointsUtils } from '../../utils/documentBreakpoints.util';
import { EDrawerPosition } from '../../enums/drawer.enum';
import Loader from '../../utils/loaderHelper.util';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  // флаг видимости обертки
  visible?: boolean;
  // массив фильтров для отображения
  filters: Array<IFilter>;
  // лоадер при сабмите
  submitLoader?: Loader;
  getFilterBaseProps?(filter: IFilter): IBaseFilterProps;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'cancel'): void;
  (e: 'submit', filters: Array<IFilterItem>): void;
}>();

const watchSubscription = new WatchSubscription();

const internalFilters = ref<Array<IFilterItem>>([]);

const selectedFilters = computed(() => FilterUtils.getSelectedFilters(internalFilters.value));
const position = computed(() => DocumentBreakpointsUtils.getIsRenderedMobile() ? EDrawerPosition.Bottom : EDrawerPosition.Right);

const isFiltersInvaild = computed(() => internalFilters.value?.some((filter) => filter?.filterState?.value?.IsInvalid));

function onSubmit(): void {
  emit('submit', cloneDeep(internalFilters.value));
}

function copyFilters(filters = props.filters): void {
  internalFilters.value = cloneDeep(filters);
}

function onClearAllFilters(): void {
  FilterUtils.clearAllFilters(internalFilters);
}

function onResetFitler(filterItem: IFilterItem): void {
  filterItem.filterState = filterItem.filter;
}

watchSubscription.add(
  watch(
    () => props.visible,
    (newVisible) => newVisible && copyFilters(props.filters),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
.filters-drawer__content {
  :deep(.filter) {
    .popper {
      .mm-range {
        .mm-input__label {
          transform: translateY(-16px);
        }
      }
    }
  }
}

.filters-drawer__item {
  :deep(.mm-range--is-invalid) {
    .mm-input {
      .mm-input__icon {
        top: 50%
      }
    }
  }
}

.filters-drawer {
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 12px 37px 28px 0;
    padding-left: 40px;

    &-actions {
      display: flex;
      flex-flow: row wrap;
      gap: 4px;
      margin-bottom: 32px;

      &__item {
        width: 100%;

        .filter {
          max-width: 100%;

          :deep(.drawer-filter) {
            flex-wrap: wrap;
            width: 100%;
          }
        }
      }
    }
  }

  &__item {
    margin-bottom: 36px;

    :deep(.mm-range) {
      .mm-input {
        padding-bottom: 0;

        .mm-input__input-container {
          height: auto;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__clear {
    width: max-content;
    padding: 9px 16px;
  }
}

@media only screen and (max-width: 1200px) {
  :deep(.drawer-filter) {
    padding: 6px 8px !important;

    & > span {
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }

  .filters-drawer__clear {
    padding: 7px 8px !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

@media only screen and (max-width: 767px) {
  .filters-drawer {
    &__content {
      padding: 0 20px;

      :deep(.mm-range) {
        width: 100% !important;

        .mm-input {
          width: 100% !important;
        }
      }
    }
  }

  :deep(.filters-drawer__item-title) {
    margin-left: 20px;
  }
}
</style>
